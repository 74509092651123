import React from 'react'
import { Route, RouteComponentProps, Switch } from '@deal/router'
import Redirect from '#src/app/components/Redirect'
import { NotFound } from '#src/app/components/Errors'

export interface ExpertShortLinkRedirectRouteParams {
  expertVanityId: string
}

const ExpertShortLinkRedirect: React.FC<
  React.PropsWithChildren<RouteComponentProps<ExpertShortLinkRedirectRouteParams>>
> = ({ match }) => {
  const expertVanityId = match.params.expertVanityId

  return <Redirect to={`/experts/${expertVanityId}?e=${expertVanityId}`} />
}

export const ExpertShortLinkRedirectRouter: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Switch>
      <Route path="/e/:expertVanityId" component={ExpertShortLinkRedirect} />
      <Route component={NotFound} />
    </Switch>
  )
}
